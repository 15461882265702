<template>
    <div
        class="app-content app-container"
        element-loading-text="拼命加载中"  v-loading="loading"
         
    >
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                @submit.native.prevent
            >
                <el-form-item label="版本类型" prop="wjtType">
                    <el-select
                        style="width: 300px"
                        v-model="formInline.wjtType"
                        placeholder="版本类型"
                        prop="wjtType"
                        clearable
                         @keyup.enter.native="onSubmit"
                    >
                        <el-option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="wjtName">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.wjtName"
                        placeholder="名称"
                        clearable
                         @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <div>
                <el-button type="primary" plain @click="addReport"
                    >新增</el-button
                >
                <!-- <el-button type="danger" plain @click="allDelteClick">删除</el-button> -->
            </div>
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="名称" prop="wjtName"> </el-table-column>
                <el-table-column prop="wjtType" label="版本类型">
                </el-table-column>
                <el-table-column
                    prop="wjtYwType"
                    label="业务类型"
                     
                >
                <template slot-scope="scope">
                    <span>{{wjtYwTypeFun(scope.row.wjtYwType)}}</span>
                </template>
                </el-table-column>
                <el-table-column
                    prop="numberSurvey"
                    label="投放"
                     
                >
                    
                </el-table-column>
                <el-table-column prop="time" label="时间"  >
                    <template slot-scope="scope">{{ scope.row.time | dayjs }}</template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                        <el-button type="text" @click="editContentClick(scope.row)"
                            >编辑内容</el-button
                        >
                        <el-button type="text" @click="previewClick(scope.row)"
                            >预览</el-button
                        >
                        <el-button type="text" @click="addReport(scope.row)"
                            >复制模版</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { templateListApi, templateDeleteApi } from '@/api/templateApi.js'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            loading: false,
            typeList: [
                {
                    label: '外贸调研',
                    value: '外贸调研',
                },
                {
                    label: '商务部标准版本',
                    value: '商务部标准版本',
                },
                {
                    label: '山东兼容版',
                    value: '山东兼容版',
                },
            ],
            businessType: [
            {
                    label: '外资',
                    value: 'WZ',
                },
                {
                    label: '外贸',
                    value: 'WM',
                },
                {
                    label: '外经',
                    value: 'WJ',
                },
            ],
            formInline: {
            },
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
        }
    },
    created(){
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getTemplateFun()
    },
    methods: {
        wjtYwTypeFun(value){
            console.log('value', value);
            var list=[]
            var val = value?value.split(','):''
            this.businessType.map(item => {
                if(val.indexOf(item.value)>-1){
                    list.push(item.label)
                }
            })
            return list.toString()
        },
        // 获取模板列表
        async getTemplateFun(currentPage, pageSize){
            var start = currentPage?(currentPage * pageSize) -pageSize  :0
            var params={
                start: start,
                length: 10,
                draw: 1,
                orderStr:'time desc'
            }
            var p = Object.assign(params, this.formInline)
            var res = await templateListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
            // console.log('模板列表', this.tableData);
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            this.getTemplateFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline={
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getTemplateFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.getTemplateFun(parm.currentPage, parm.pageSize)
        },
        // 删除模板
        async allDelteClick(){
            
             var ids = this.multipleSelection.map(item =>  item.wjtId)
            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if(action ==='confirm'){
                            var params = {
                            ids: ids,
                            menuType: 'delete',
                        }
                        var res = await templateDeleteApi(params)
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功',
                            })
                            this.getTemplateFun()
                        } else {
                            this.$message({
                                type: 'error',
                                message: '删除失败：' + res.message,
                            })
                        }
                        }
                        
                    },
                }
            )
        },
        // 新增模板
        addReport(row) {
            this.$router.push({
                path: '/questionnaire/formboard/add',
                query:{
                    id: row.wjtId,
                    type:'copy'
                }
            })
        },
        // 编辑模板
        editClick(row) {
            console.log('row', row)
            this.$router.push({
                path: '/questionnaire/formboard/add',
                query:{
                    id:row.wjtId
                }
            })
        },
        // 编辑内容
        editContentClick(row) {
            console.log('row', row)
            this.$router.push({
                path: '/questionnaire/formboard/editContent',
                query:{
                    id:row.wjtId
                }
            })
        },
        // 预览模板
        previewClick(row) {
            console.log('row', row)
            this.$router.push({
                path: '/questionnaire/formboard/preview',
                query:{
                    id:row.wjtId
                }
            })
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
